.nightLayer {
    position: absolute;
    top: 0;
    left: 0;

    z-index: 1010;

    min-width: 100%;
    min-height: 100%;

    opacity: 0;

    background-color: black;

    pointer-events: none;

    animation: goDarkAnimationRev 2s normal;
}

@keyframes goDarkAnimation { 
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 0.8;
    }
  }

  @keyframes goDarkAnimationRev { 
    0% {
      opacity: 0.8;
    }
  
    100% {
      opacity: 0;
    }
  }

.nightLayer__on {
    opacity: 0.8;
    animation: goDarkAnimation 2s normal;
}