.painting {
    width: 800px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 50px;

    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 768px) {
  .painting {
      width: 100%;
}
}

.painting__picture {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 60%;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .painting__picture {
      height: 40%;
}
}

.painting__picture > img {
  max-width: 60%;
  max-height: 100%;
  height: auto;
}

.painting__header {
  text-align: center;
  font-size: 1.7em;

  padding-top: 30px;
}

@media only screen and (max-width: 768px) {
  .painting__header {
      font-size: 1.3em;    
  }
}

.painting__info {
  display: flex;
  justify-content: center;

  width: 100%;

  font-size: 1.1em; 

  padding-top: 20px;
}

@media only screen and (max-width: 768px) {
  .painting__info {    
      font-size: 0.7em; 
  }
}

.painting__info > div {
  opacity: 0.5;
  width: 33.3%;
  text-align: center;
}

.painting__text {
  padding-top: 20px;

  text-align: center;
}

.painting__purchase {
  position: relative;
  width: 100%;
  

  margin-left: auto;
  margin-right: auto;

  display: flex;
  justify-content: center;

  margin-top: 30px;

}

.painting__purchase__button {
  background-color: #ff8a00;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  font-size: 1.2em;

  border-radius: 30px;

  cursor: pointer;
}

.purchase__button__link {
  font-weight: bold;
}
