.paragraph {
    width: 800px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 50px;

    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 768px) {
    .paragraph {
        padding-top: 70px;
        width: 100%;
    }
}

.paragraph__picture {
    text-align: center;
}

.paragraph__picture img {
    border-radius: 50%;
    width: 60%;
    height: auto;
}

.paragraph__title {

    font-size: 2em;
    text-align: center;

    padding-bottom: 25px;
    padding-top: 40px;
}

@media only screen and (max-width: 768px) {
    .paragraph__title {
        font-size: 1.6em;
    }
}

.paragraph__text {
    height: 100%;

    text-align: justify;

    padding-left: 20px;
    padding-right: 20px;

    font-size: 1.3em;
}

@media only screen and (max-width: 768px) {
        .paragraph__text {
            font-size: 0.9em;
        }
}

.paragraph__text h4 {
    text-align: center;
}

.paragraph__quote { 
    text-align: center;

    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;

    font-size: 2em;
    opacity: 0.4;
}

@media only screen and (max-width: 768px) {
    .paragraph__quote {
        font-size: 1.3em;
    }
}

.paragraph__quote_signature {
    display: block;
    width: 100%;
    padding-top: 10px;
    text-align: right;
}