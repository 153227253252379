.bio {
    width: 800px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 50px;

    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 768px) {
    .bio {
        width: 100%;
        padding-top: 70px;
    }
}

.bio__picture {
    text-align: center;
}

.bio__picture img {
    border-radius: 50%;
    width: 60%;
    height: auto;
}

.bio__quote { 
    text-align: center;

    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;

    font-size: 2em;
    opacity: 0.4;
}

@media only screen and (max-width: 768px) {
    .bio__quote {
        font-size: 1.3em;
    }
}

.bio__quote_signature {
    display: block;
    width: 100%;
    padding-top: 10px;
    text-align: right;
}

.bio__visitme {
    padding-top: 20px;
    text-align: center;

    font-size: 1.3em;

    line-height: 1.7em;
}

@media only screen and (max-width: 768px) {
    .bio__visitme {
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    
        font-size: 0.9em;
    
        line-height: 1.6em;
    }
}

.bio__visitme > a {
    font-weight: bold;
    color: black;
}


.bio__achievements {
    text-align: center;

    padding-top: 20px;
}

.bio__achievements__title {
    font-size: 2em;

    padding-bottom: 20px;
}

.achievements__list {
    font-size: 1.3em;

    padding-bottom: 10px;
}

@media only screen and (max-width: 768px) {
    .bio__achievements {
        padding-left: 10px;
        padding-right: 10px;
    }

    .bio__achievements__title {
        font-size: 1.6em;
    }

    .achievements__list {
        font-size: 0.9em;
    }
}