/*
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -ms-content-zooming: none;
}*/

html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  width: 100vw;
  height: 100vh;
}

.pano {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #BBB;
}

.loader > img {
  align-self: center;
  max-width: 50%;
  width: auto;
  
  filter: invert(87%) sepia(0%) saturate(3696%) hue-rotate(113deg) brightness(86%) contrast(98%);
}

.loader__nahravame {
  align-self: center;
  font-size: 40pt;
  font-family: AbrilFatface;
}


@keyframes blinker {
  50% {
      opacity: 0;
    }
}

.waiting_for_assets {
  animation: blinker 3s linear infinite;
}

.hintspot_100 {
  width: 100px !important; 
  height: 100px !important;
}

.hintspot_150 {
  width: 150px !important; 
  height: 150px !important;
}

.image_to_orange {
  filter: invert(64%) sepia(86%) saturate(1834%) hue-rotate(0deg) brightness(103%) contrast(100%);
}

/*
  PULZUJICI HINTSPOT
*/
.lds-ripple {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: -40px;
  margin-top: -40px;
  perspective: 500px
}

@media only screen and (max-width: 768px) {
  .lds-ripple {
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px;
  }
}

.lds-ripple:hover div {
  border-color: red;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #FF8C00;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  perspective: 500px
}

.lsd-ripple-white div {
  border: 4px solid white;
}

@media only screen and (max-width: 768px) {
  .lds-ripple div {
    border: 3px solid #FF8C00;
  }

  .lsd-ripple-white div {
    border: 3px solid white;
  }
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
  perspective: 500px
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0.25;
  }
}

@media only screen and (max-width: 768px) {
  @keyframes lds-ripple {
    0% {
      top: 18px;
      left: 18px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 36px;
      height: 36px;
      opacity: 0.25;
    }
  }
}

/* NOTE ICON */
.noteIcon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  cursor: pointer;
  background-color: rgba(255,255,255,0.2);
  border-radius: 50%;
  padding: 10px 5px 5px 10px;
  text-align: center;
}

.noteIcon img {
  width: 30px;
  height: 30px;
  /*filter: invert(100%) sepia(6%) saturate(387%) hue-rotate(263deg) brightness(117%) contrast(87%);*/
}

.noteIcon__notPlay img {
  opacity: 0.2
}

/*
  GENERATED BY IA
*/

.byAi {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 0px;
  z-index: 1001;
  background-color: rgba(255,255,255,0.2);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 5px 10px 5px 10px;
  text-align: center;
  color: #222;
  font-size: 1.5rem;
}

.show {
  display: block;
}

/*

FLOATING BAR

*/
.floatingBar {
  position: fixed;
  top: 20px;
  right: 20px;  
  z-index: 1000;

  display: flex;
  justify-content: center;
  gap: 10px;
}

.floatingBar > .taskbar__item {
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  .floatingBar {
    top: 60px;
    right: 10px;  
    z-index: 1000;
  
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
  
}