.escape {
    display: none;

    position: absolute;
    bottom: -50px;
    left: 50%;
    margin-left: -100px;

    width: 200px;
    height: 200px;
}

.escape__show {
    display: block;
}

.escape__radar {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    border-radius: 50%;

    background-color: rgba(255, 255, 255, 0.3);
}

.escape__close {
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1030;

    background-color: white;

    width: 50px;
    height: 50px;

    border-radius: 50%;

    font-size: 25px;

    cursor: pointer;
}

.escape__close img {
    width: 70%;
    height: auto;
}

@media only screen and (max-width: 768px) {
    .escape__close {
        width: 40px;
        height: 40px;
    }
}

.escape__radar__look {
    position: absolute;
    top: 5px;

    width: 15%;
    height: auto;

    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(111deg) brightness(98%) contrast(107%);
}

.escape__radar__aitext {
    position: absolute;
    top: 23%;

    width: 60%;
    height: auto;

    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(111deg) brightness(98%) contrast(107%);
}
