.taskbar {
    position: fixed;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 50px;

    background-color: white;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    z-index: 1020;
}

@media only screen and (max-width: 768px) {
}

.taskbar__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.taskbar__left > div {
    margin-left: 15px;
}

.taskbar__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.taskbar__right > div {
    margin-right: 15px;
}

@media only screen and (max-width: 768px) { 
    .taskbar__left {
        width: 100%;
        justify-content: flex-start;
    }

    .taskbar__left > div {
        margin-left: 10px;
    }

    .taskbar__left > div:last-child {
        margin-right: 7px;
    }

    .taskbar__right {
        width: 100%;
        align-items: flex-end;
    }

    .taskbar__right > div {
        margin-right: 10px;
    }

    .taskbar__right > div:last-child {
        margin-right: 7px;
    }
}

/*
    BIO
*/
.taskbar__bio__url {
    font-weight: bold;
    color: black;
    text-decoration: none;
}

/*
    TASKBAR LOGO
*/
.taskbar__logo {
    cursor: pointer;
}

.taskbar__logo img {
    height: 25px;
    width: auto;
}

@media only screen and (max-width: 768px) {
    .taskbar__logo {
       display: none;
    }
}