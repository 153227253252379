.shopBag {
    width: 800px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 50px;

    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 768px) {
    .shopBag {
        width: 100%;
        padding-top: 70px;
    }
}

.shopBag__picture {
    text-align: center;
}

.shopBag__picture img {
    border-radius: 50%;
    width: 60%;
    height: auto;
}


.shopBag__link {
    display: none;
    font-size: 1.6em;

    text-align: center;

    padding-top: 40px;

    line-height: 1.5em;
}

@media only screen and (max-width: 768px) { 
    .shopBag__link {
        font-size: 0.9em;
    }
}

.shopBag__link__text {
    opacity: 0.4;
}


.shopBag__link a {
    color: black;
}

.shopBag__quote { 
    text-align: center;

    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;

    font-size: 2em;
    opacity: 0.4;
}

@media only screen and (max-width: 768px) {
    .shopBag__quote {
        font-size: 1.3em;
    }
}

.shopBag__quote_signature {
    display: block;
    width: 100%;
    padding-top: 10px;
    text-align: right;
}

.shopBag__text {
    text-align: justify;

    padding-left: 50px;
    padding-right: 50px;
    padding-top: 40px;

    font-size: 1.3em;
}

.shopBag__text a {
    color: black;
}

@media only screen and (max-width: 768px) {
    .shopBag__text {
        font-size: 0.9em;
    }
}
