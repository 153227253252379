.tutorial {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: none;
    align-items: center;
    justify-content: center;

    opacity: 0;
}

@media only screen and (max-width: 768px) {
    .tutorial {
        width: 100%;
    }
}

.tutorial__image {
    width: 15%;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 50px;
}

.tutorial__image img {
    width: 100%;
    height: auto;
}

.tutorial__show {
    display: flex;
}

.tutorial__fadeout {
    animation: tutorialFadeOut 2s ease;
    animation-fill-mode: forwards;
}

.tutorial__fadein {
    animation: tutorialFadeIn 2s ease;
    animation-fill-mode: forwards;
}

.tutorial__fadeinout {
    animation: tutorialFadeInOut 4s ease;
    animation-fill-mode: forwards;
}

@keyframes tutorialFadeIn {
    100% {
        opacity:1;
    }
}

@keyframes tutorialFadeOut {
    100% {
        opacity:0;
    }
}

@keyframes tutorialFadeInOut {
    0%,100% { opacity: 0 }
    75% { opacity: 1 }
}
