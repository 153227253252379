.enter__loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-image: url('../img/enterPhoto.jpg');
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    z-index: 1100;
}

@media only screen and (max-width: 768px) {
  .enter__loader {
    background-image: url('../img/enterPhotoSmall.jpg');
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.enter__loader__dimmer {
    padding-top: 20px;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: black;
    
}

.enter__loader img {
    filter: invert(0%) sepia(4%) saturate(26%) hue-rotate(236deg) brightness(89%) contrast(100%);
}

.enter__loader__fadeout {
    animation: fadeOut 3s ease;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    100% {
        opacity:1;
    }
}

@keyframes fadeOut {
    100% {
        opacity:0;
    }
}

.enter__loader_width_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 70%;
    height: 100%;
}

.enter__loader__signature {
    width: 80%;
}

.enter__loader__signature img {
    width: 100%;
}

.enter__loader__artstudio {
    width: 80%;
    text-align: right;
    padding-top: 30px;
    padding-right: 10%;
}

.enter__loader__artstudio  img {
    width: 20%;
}

/*
    LDS RING
*/
.enter__loader__ring {
    position: absolute;
    bottom: 100px;
}

.loader__ring__fadeout {
    animation: fadeOut 2s ease;
    animation-fill-mode: forwards;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 80px;
    height: 80px;
    margin: 8px;
    border: 4px solid #C4C4C4;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #C4C4C4 transparent transparent transparent;
  }

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

/*
    LDS RIPPLE
*/
.enter__loader__ripple {
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.loader__ripple__fadeIn {
    animation: fadeIn 2s ease;
    animation-fill-mode: forwards;
}

.loader__ripple__visible {
    visibility: visible;
}

.loader__ripple_text {
    text-align: center;
    padding-left: 25px;
}

.loader__ripple_text img {
    width: 150px;
    height: auto;
}

@media only screen and (max-width: 768px) {
  .loader__ripple_text img {
      width: 100px;
      height: auto;
  }
}

.loader__lds-ripple {
    display: inline-block;
    position: relative;
    width: 150px;
    height: 150px;
    margin-top: -50px;

    cursor: pointer;
  }

  .loader__lds-ripple div {
    position: absolute;
    border: 4px solid black;
    opacity: 1;
    border-radius: 50%;
    animation: loader__lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .loader__lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes loader__lds-ripple {
    0% {
      top: 75px;
      left: 75px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 150px;
      height: 150px;
      opacity: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .enter__loader__artstudio {
        padding-top: 15px;
    }

    .loader__lds-ripple {
        width: 100px;
        height: 100px;
        margin-top: -25px;
    }

    @keyframes loader__lds-ripple {
        0% {
          top: 50px;
          left: 50px;
          width: 0;
          height: 0;
          opacity: 1;
        }
        100% {
          top: 0px;
          left: 0px;
          width: 100px;
          height: 100px;
          opacity: 0;
        }
      }

    .loader__ripple_text {
        font-size: 1em;
    }
  }