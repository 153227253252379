.generic__modal__close {
    position: absolute;
    bottom: 25px;
    left: 50%;
    margin-left: -25px;
 

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 99999;

    background-color: white;

    width: 50px;
    height: 50px;

    border-radius: 50%;

    font-size: 25px;

    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .generic__modal__close {
        width: 40px;
        height: 40px;

        bottom: 30px;
        margin-left: -20px;
    }
 
}

.generic__modal__close img {
    width: 70%;
    height: auto;
}

.generic__modal__close__hidden {
    display: none;
}