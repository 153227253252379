.taskbar__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    cursor: pointer;
}

.taskbar__item__text {
    padding-left: 0px;
}

.taskbar__item__icon img {
    height: 30px;
    width: auto;
}