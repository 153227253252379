.music__icon {
    cursor: pointer;
    margin-top: 0px;
}

.music__icon img {
    color: black;
    width: 30px;
    height: 30px;
}

.music__icon__notPlay img {
    opacity: 0.2
  }