.taskbar__top {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 50px;

    background-color: white;

    display: none;
    align-items: center;
    justify-content: center;

    z-index: 1020;
}

.taskbar__top img {
    display: block;
    height: 25px;
    width: auto;
}


@media only screen and (max-width: 768px) {
    .taskbar__top {
        display: flex;
    }
}